import { createContext, useContext, useEffect, useState } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { auth } from '../firebase'

const userAuthContext = createContext()

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({})

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }
  function logOut() {
    return signOut(auth)
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider()
    return signInWithPopup(auth, googleAuthProvider)
  }

  // function setUpRecaptha(number) {
  //   const recaptchaVerifier = new RecaptchaVerifier(
  //     'recaptcha-container',
  //     {},
  //     auth
  //   )
  //   recaptchaVerifier.render()

  //   signInWithPhoneNumber(auth, number, recaptchaVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent, proceed with verification
  //       console.log('SMS sent', confirmationResult)
  //       window.confirmationResult = confirmationResult // Save for later verification
  //     })
  //     .catch((error) => {
  //       console.error('Error during sign-in with phone number:', error)
  //       alert(error.message) // Display error to user
  //     })
  // }

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {},
      auth
    )
    recaptchaVerifier.render()
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log('Auth', currentuser)
      setUser(currentuser)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        setUpRecaptha,
      }}
    >
      {children}
    </userAuthContext.Provider>
  )
}

export function useUserAuth() {
  return useContext(userAuthContext)
}
