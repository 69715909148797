// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyA9hEo5AE2KLwfxEVVm1y-fHst8aot-Dlk",
//   authDomain: "gulldan-99374.firebaseapp.com",
//   projectId: "gulldan-99374",
//   storageBucket: "gulldan-99374.appspot.com",
//   messagingSenderId: "49662969360",
//   appId: "1:49662969360:web:67415200729fe0ff78ac95",
//   measurementId: "G-CPM0C30XZK",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);
// export default app;
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA9hEo5AE2KLwfxEVVm1y-fHst8aot-Dlk',
  authDomain: 'gulldan-99374.firebaseapp.com',
  projectId: 'gulldan-99374',
  storageBucket: 'gulldan-99374.appspot.com',
  messagingSenderId: '49662969360',
  appId: '1:49662969360:web:67415200729fe0ff78ac95',
  measurementId: 'G-CPM0C30XZK',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
export default app
export const auth = getAuth(app)
